import React, { useEffect, useState } from 'react';
import './userdetail.css';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowRoundBack } from "react-icons/io";

function UserDetails() {
  const params = useParams();
  const [addForm, setAddForm] = useState({});
  const [formDetail, setFormDetail] = useState([]);
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async (id) => {
      if (id) {
        try {
          let response = await axios.get(`http://jamaath.kasarcoding.com/apis/findchild/${id}`);
          response = response.data;
          if (response.success) {
            const result = response.result;
            setAddForm(result.addForm);
            setFormDetail(result.formDetail);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchData(params?.id);
  }, [params?.id]);

  const renderRow = (data, index) => {
    const rowData = [
      index + 1,
      data?.name,
      data?.daysInJamahath,
      data?.age,
      data?.language,
      data?.lastThreeYearsPlaces,
      data?.passportNumber,
      data?.homeplaceWork,
      data?.firstForeignJamath
    ];

    // If rowData length is less than 8, add empty strings to make it 8
    while (rowData.length < 8) {
      rowData.push('');
    }

    return (
      rowData.map((cell, i) => (
        <td key={i} className="table-cell">{cell}</td>
      ))
    );
  };

  const handlePrint = () => {
    document.title = `${addForm.state}_${addForm.jamathNumber}`;
    window.print();
  };

  const goBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <>
      <div className="table-container">
        <table className="custom-table">
          <thead>
            <tr>
              <th colSpan="9" className="header-title">
                ماہ کی غیر ملکی جماعت قوائف پرابے۔
                <div className="sub-header">
                  അഞ്ച് മാസ വിദേശ ജമാഅത്തിന്റെ കവായിഫ്
                </div>
              </th>
            </tr>
            <tr>
              <th colSpan="9" className="section-header">
                :صوبه <span style={{ fontSize: "12px", }}>(സ്റ്റേറ്റ്)</span> : <span style={{ fontSize: "14px" }}>{addForm.state}</span> ضلع <span style={{ fontSize: "12px" }}>(ജില്ല)</span>  :  <span style={{ fontSize: "14px" }}>{addForm.district}</span> خرچ فی کس <span style={{ fontSize: "12px" }}>(ചിലവ്)</span>  :  <span style={{ fontSize: "14px" }}>{addForm.expense}</span> تاریخ روانگی <span style={{ fontSize: "12px" }}>(റവാംഗി തിയതി)</span>  :  <span style={{ fontSize: "14px" }}>{addForm.rawangiDate}</span><br /> جماعت نمبر <span style={{ fontSize: "12px" }}>(ജമാഅത്തിന്റെ നമ്പർ)</span>  :  <span style={{ fontSize: "14px" }}>{addForm.jamathNumber}</span>
              </th>
            </tr>
            <tr className="field-names">
              <th>نمبر شمار</th>
              <th>نام <br /><span style={{ fontSize: "8.5px" }}>പേര്</span></th>
              <th>چار ماہ کتنی مرتبہ لگے؟ <br /><span style={{ fontSize: "8.5px" }}> നാലു മാസം എത്ര തവണ പോയിട്ടുണ്ട്</span></th>
              <th>عمر <br /><span style={{ fontSize: "8.5px" }}>വയസ്സ്</span> </th>
              <th>زبان <br /><span style={{ fontSize: "8.5px" }}>സംസാരിക്കുന്ന ഭാഷ</span> </th>
              <th> پچھلے تین سالوں میں چلہ کب اور کہاں لگا <br /> <span style={{ fontSize: "8.5px" }}>കഴിഞ്ഞ മൂന്ന് വർഷങ്ങളിലെ ചില്ല എവിടെയൊക്ക എപ്പോൾ കൊടുത്തു </span> </th>
              <th>پاسپورٹ نمبر<br /><span style={{ fontSize: "8.5px" }}>പാസ്പോർട്ട് നംബർ </span> </th>
              <th>مقامی اعمال کی پابندی  <br /><span style={{ fontSize: "8.5px" }}>മഖാമി അമലിന്റെ കൃത്യനിഷ്ടത</span> </th>
              <th> پہلے جماعت کا سفر کب اور کہاں؟ <br /><span style={{ fontSize: "8.5px" }}>ആദ്യത്തെ വിദേശ ജമാഅത്തിൽ എപ്പോൾ, എവിടെ പുറപ്പെട്ടു</span></th>
            </tr>
          </thead>
          <tbody>
            {
              [0, 1, 2, 3, 4, 5, 6, 7].map((index) => {
                const data = formDetail[index]

                return (
                  <tr key={index}>
                    {renderRow(data, index)}
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <div className="footer">
          <div className="footer-left">
            <p>تجویز <span style={{ fontSize: "12px" }}>(താജ് വീസ്): {addForm.thajwees}</span></p>
            <p>رابطہ نمبر <span style={{ fontSize: "12px" }}>(ബന്ധപെട്ട നമ്പർ):</span></p>
            <p>(1): {addForm.numberOne}</p>
            <p>(۲): {addForm.numberTwo}</p>
          </div>
          <div className="footer-right">
            <p>فیصلہ کرنا <span style={{ fontSize: "12px" }}>(തീരുമാനിക്കപ്പെട്ട രാജ്യം): {addForm.chosenCountry}</span></p>
            <p>حلقہ <span style={{ fontSize: "12px" }}>(ഹൽക): {addForm.halka}</span></p>
            <p>نوٹ <span style={{ fontSize: "12px" }}>(നോട്ട്): {addForm.note}</span></p>
          </div>
        </div>
        <div className="print-button-container">
          <button style={{ float: "left" }} className="back-button ms-2 mt-2" onClick={goBack}>
            <IoIosArrowRoundBack size={'25px'} />
          </button>
          <button onClick={handlePrint} class="Btn">
            <svg class="svgIcon" viewBox="0 0 384 512" height="1em" xmlns="http://www.w3.org/2000/svg"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path></svg>
            <span class="icon2"></span>
            <span class="tooltip">Download</span>
          </button>
        </div>
      </div>
    </>
  );
}

export default UserDetails;
