import React from 'react'
import { useState } from 'react';
import './login.css'
import axios from 'axios';
import { useSnackbar } from "notistack";
import { useNavigate } from 'react-router-dom';

function LoginForm() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate()
  const [isLogin, setIsLogin] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const switchModeHandler = () => {
    setIsLogin((prevMode) => !prevMode);
    setFormData({
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
    });
  };

  const inputChangeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const { name, email, password, confirmPassword } = formData;

    if (!isLogin && password !== confirmPassword) {
      return alert('Passwords do not match');
    }

    try {
      const response = await axios.post(
        `http://jamaath.kasarcoding.com/apis/${isLogin ? 'login' : 'register'}`,
        { name, email, password }
      );

      const { token } = response.data;
      localStorage.setItem('token', token);
      enqueueSnackbar(isLogin ? 'Login Successfully' : 'Register Successfully', { variant: 'success' });
      navigate('/admin')

    } catch (error) {
      enqueueSnackbar(error.response?.data?.message || 'An error occurred', { variant: 'error' });
      console.error(error);
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-box">
        <h2>{isLogin ? 'Login' : 'Register'}</h2>
        <form onSubmit={submitHandler}>
          {!isLogin && (
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={inputChangeHandler}
                required={!isLogin}
              />
            </div>
          )}
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={inputChangeHandler}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={inputChangeHandler}
              required
            />
          </div>
          {!isLogin && (
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={inputChangeHandler}
                required={!isLogin}
              />
            </div>
          )}
          <button type="submit" className="submit-btn">
            {isLogin ? 'Login' : 'Register'}
          </button>
        </form>
        <button onClick={switchModeHandler} className="switch-btn">
          Switch to {isLogin ? 'Register' : 'Login'}
        </button>
      </div>
    </div>
  );
}
export default LoginForm
