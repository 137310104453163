/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './form.css';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { IoIosArrowRoundBack } from "react-icons/io";

function AddFirstForm() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const params = useParams();

  const [formData, setFormData] = useState({
    state: '',
    district: '',
    expense: '',
    rawangiDate: null,
    jamathNumber: '',
    numberOne: '',
    numberTwo: '',
    thajwees: '',
    chosenCountry: '',
    halka: '',
    note: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value.toString()
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedFormData = formData
      
      if (params.id) {
        await axios.put(`http://jamaath.kasarcoding.com/apis/updateform/${params.id}`, updatedFormData)
        enqueueSnackbar('Form updating Successfully', { variant: 'success' })
        navigate('/admin/table')
      } else {
        const response = await axios.post('http://jamaath.kasarcoding.com/apis/saveAddForm', updatedFormData);
        const result = response.data.result; // Handle success or redirect as needed
        navigate(`/admin/forms-details/${result}`);
      }

    } catch (error) {
      console.error('Error saving form data:', error);
    }
  };

  useEffect(() => {
    const fetchData = async (id) => {
      if (id) {
        try {
          let response = await axios.get(`http://jamaath.kasarcoding.com/apis/getuserdata/${id}`);
          response = response.data;
          if (response.success) {
            const result = response.result;
            setFormData(result);
          }
        } catch (err) {
          console.log('Error fetching data', err);
          enqueueSnackbar('Error fetching data', { variant: 'error' });
        }
      } else {
        setFormData({
          state: '',
          district: '',
          expense: '',
          rawangiDate: null,
          jamathNumber: '',
          numberOne: '',
          numberTwo: '',
          thajwees: '',
          chosenCountry: '',
          halka: '',
          note: ''
        });
      }
    };
    fetchData(params?.id);
  }, [params?.id]);

  const goBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <div className="add-form-main-box">
      <button style={{ float: "left" }} className="back-button ms-2 mt-2" onClick={goBack}>
        <IoIosArrowRoundBack size={'25px'} />
      </button>
      <h1 className="add-form-title">ADD FORM</h1>
      <div className="add-form-container">
        <form className="add-form" onSubmit={handleSubmit}>
          <label className="add-form-label">State</label>
          <input
            className="add-form-input"
            type="text"
            name="state"
            value={formData.state}
            onChange={handleChange}
            required
          />

          <label className="add-form-label">District</label>
          <input
            className="add-form-input"
            type="text"
            name="district"
            value={formData.district}
            onChange={handleChange}
            required
          />

          <label className="add-form-label">Expense</label>
          <input
            className="add-form-input"
            type="number"
            name="expense"
            value={formData.expense}
            onChange={handleChange}
            required
          />

          <label className="add-form-label">Rawangi Date</label>
          <input
            className="add-form-input"
            type="date"
            name="rawangiDate"
            value={formData.rawangiDate}
            onChange={handleChange}
            required
          />

          <label className="add-form-label">Jamath Number</label>
          <input
            className="add-form-input"
            type="number"
            name="jamathNumber"
            value={formData.jamathNumber}
            onChange={handleChange}
            required
          />

          <label className="add-form-label">Number1</label>
          <input
            className="add-form-input"
            type="number"
            name="numberOne"
            value={formData.numberOne}
            onChange={handleChange}
            required
          />

          <label className="add-form-label">Number2</label>
          <input
            className="add-form-input"
            type="number"
            name="numberTwo"
            value={formData.numberTwo}
            onChange={handleChange}
            required
          />

          <label className="add-form-label">Thajwees</label>
          <input
            className="add-form-input"
            type="text"
            name="thajwees"
            value={formData.thajwees}
            onChange={handleChange}
            required
          />

          <label className="add-form-label">Chosen Country</label>
          <input
            className="add-form-input"
            type="text"
            name="chosenCountry"
            value={formData.chosenCountry}
            onChange={handleChange}
            required
          />

          <label className="add-form-label">Halka</label>
          <input
            className="add-form-input"
            type="text"
            name="halka"
            value={formData.halka}
            onChange={handleChange}
            required
          />

          <label className="add-form-label">Note</label>
          <textarea
            className="add-form-textarea"
            name="note"
            value={formData.note}
            onChange={handleChange}
          ></textarea>

          <button className="add-form-submit-btn" type="submit">
            {params.id ? "update" : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
}
export default AddFirstForm;
