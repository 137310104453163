import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Formedit.css';
import { useParams, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { IoIosArrowRoundBack } from "react-icons/io";

function FormDetailEdit() {
    const params = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        age: '',
        daysInJamahath: '',
        language: '',
        lastThreeYearsPlaces: '',
        passportNumber: '',
        homeplaceWork: '',
        firstForeignJamath: ''
    });

    const [formDataList, setFormDataList] = useState([]);
    const [editIndex, setEditIndex] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value.toString()
        });
    };

    const handleAddData = async (e) => {
        e.preventDefault();

        if (editIndex !== null) {
            const updatedList = [...formDataList];
            updatedList[editIndex] = formData;
            setFormDataList(updatedList);

            try {
                const id = formDataList[editIndex]._id;
                await axios.put(`http://jamaath.kasarcoding.com/apis/updateformdetails/${id}`, formData);
                enqueueSnackbar('Updated Successfully', { variant: 'success' });
            } catch (err) {
                console.log('Error updating form details', err);
            }
            setEditIndex(null);
        } else {
            setFormDataList([...formDataList, formData]);
        }
        clearForm();
    };

    const handleEdit = (index) => {
        const selectedData = formDataList[index];
        setFormData(selectedData);
        setEditIndex(index);
    };

    const handleDelete = (data) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this item?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        const id = data._id;
                        try {
                            await axios.delete(`http://jamaath.kasarcoding.com/apis/deleteform/${id}`);
                            enqueueSnackbar('Form deleted successfully', { variant: 'info' });

                            const updatedList = formDataList.filter(x => x._id !== id);
                            setFormDataList(updatedList);
                        } catch (err) {
                            console.log('Error deleting form', err);
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        // Do nothing if "No" is clicked
                    }
                }
            ]
        });
    };

    const clearForm = () => {
        setFormData({
            name: '',
            age: '',
            daysInJamahath: '',
            language: '',
            lastThreeYearsPlaces: '',
            passportNumber: '',
            homeplaceWork: '',
            firstForeignJamath: ''
        });
        setEditIndex(null);
    };

    const isFormValid = () => {
        return (
            formData.name &&
            formData.age &&
            formData.daysInJamahath &&
            formData.language &&
            formData.lastThreeYearsPlaces &&
            formData.passportNumber &&
            formData.homeplaceWork &&
            formData.firstForeignJamath
        );
    };

    useEffect(() => {
        const fetchData = async (id) => {
            if (id) {
                try {
                    let response = await axios.get(`http://jamaath.kasarcoding.com/apis/getuserdetaildata/${id}`);
                    response = response.data;
                    if (response.success) {
                        const result = response.result;
                        setFormDataList(result);
                    }
                } catch (err) {
                    console.log('Error fetching data', err);
                }
            }
        };
        fetchData(params?.id);
    }, [params?.id]);

    const goBack = () => {
        navigate(-1); // Navigate to the previous page
    };

    return (
        <div className="form-detail-main">
            {/* Back Button */}
            <button className="back-button" onClick={goBack}>
                <IoIosArrowRoundBack size={'25px'} />
            </button>
            <div className="form-detail-box">
                <h1 className="form-detail-title">Add Details</h1>
                <form className="form-detail-form" onSubmit={handleAddData}>
                    <div className="form-detail-row">
                        <div className="form-detail-col">
                            <label className="form-detail-label">Name</label>
                            <input
                                type="text"
                                className="form-detail-input"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-detail-col">
                            <label className="form-detail-label">Age</label>
                            <input
                                type="number"
                                className="form-detail-input"
                                name="age"
                                value={formData.age}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-detail-row">
                        <div className="form-detail-col">
                            <label className="form-detail-label">How many days 4 month jamahath</label>
                            <input
                                type="number"
                                className="form-detail-input"
                                name="daysInJamahath"
                                value={formData.daysInJamahath}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-detail-col">
                            <label className="form-detail-label">Language</label>
                            <input
                                type="text"
                                className="form-detail-input"
                                name="language"
                                value={formData.language}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-detail-row">
                        <div className="form-detail-col">
                            <label className="form-detail-label">Last 3 years jamahath place and date</label>
                            <input
                                type="text"
                                className="form-detail-input"
                                name="lastThreeYearsPlaces"
                                value={formData.lastThreeYearsPlaces}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-detail-col">
                            <label className="form-detail-label">Passport Number</label>
                            <input
                                type="text"
                                className="form-detail-input"
                                name="passportNumber"
                                value={formData.passportNumber}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-detail-row">
                        <div className="form-detail-col">
                            <label className="form-detail-label">Homeplace work (yes or no)</label>
                            <input
                                type="text"
                                className="form-detail-input"
                                name="homeplaceWork"
                                value={formData.homeplaceWork}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-detail-col">
                            <label className="form-detail-label">First foreign jamahath date and place</label>
                            <input
                                type="text"
                                className="form-detail-input"
                                name="firstForeignJamath"
                                value={formData.firstForeignJamath}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <button type="submit" className="form-detail-submit" disabled={!isFormValid()}>
                        {editIndex !== null ? 'Update' : 'Add'}
                    </button>
                </form>
            </div>

            {formDataList.length > 0 && (
                <div className="todo-container">
                    <h2 className="todo-heading">Form Details</h2>
                    <div style={{ overflow: "auto" }}>
                        <table className="todo-table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Age</th>
                                    <th>Days in Jamahath</th>
                                    <th>Language</th>
                                    <th>Last Three Years Places</th>
                                    <th>Passport Number</th>
                                    <th>Homeplace Work</th>
                                    <th>First Foreign Jamath</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {formDataList.map((data, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{data.name}</td>
                                        <td>{data.age}</td>
                                        <td>{data.daysInJamahath}</td>
                                        <td>{data.language}</td>
                                        <td>{data.lastThreeYearsPlaces}</td>
                                        <td>{data.passportNumber}</td>
                                        <td>{data.homeplaceWork}</td>
                                        <td>{data.firstForeignJamath}</td>
                                        <td>
                                            <button className='edit-btn' onClick={() => handleEdit(index)}>Edit</button>
                                        </td>
                                        <td>
                                            <button className='delete-btn' onClick={() => handleDelete(data)}>Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
}

export default FormDetailEdit;
