import React, { useState, useEffect } from 'react';
import './firstTable.css';
import { FaRegEdit, FaEye, FaTrash } from "react-icons/fa";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import CSS for confirm alert
import { IoIosArrowRoundBack } from "react-icons/io";

function FirstTable() {
  const [addFormData, setAddFormData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('http://jamaath.kasarcoding.com/apis/getAddForm')
      .then(response => {
        setAddFormData(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
      });
  }, [addFormData]);

  const viewBtn = (data) => {
    navigate('/view/' + data._id);
  };

  const editBtn = (Data) => {
    navigate('/admin/forms/edit/' + Data._id, {
      replace: true
    });
  };

  const editDetailBtn = (data) => {
    navigate('/admin/forms-detail-edit/' + data._id);
  };

  const deleteform = async (data) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete this item?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              await axios.delete('http://jamaath.kasarcoding.com/apis/deleteuser/' + data._id);
              enqueueSnackbar('User deleted successfully', { variant: "success" });
            } catch (err) {
              enqueueSnackbar('Please delete your children first', { variant: "warning" });
              navigate('/admin/forms-detail-edit/' + data._id);
            }
          }
        },
        {
          label: 'No',
          onClick: () => { /* Do nothing if "No" is clicked */ }
        }
      ]
    });
  };

  return (
    <div className="table-main-box">
      <h2 className="table-title">FORMS DATAS</h2>
      <div className="table-responsive">
        <table className="main-table">
          <thead>
            <tr>
              <th>State</th>
              <th>District</th>
              <th>Expense</th>
              <th>Rawangi Date</th>
              <th>Jamath Number</th>
              <th>Number1</th>
              <th>Number2</th>
              <th>Thajwees</th>
              <th>Chosen Country</th>
              <th>Halka</th>
              <th>Edit</th>
              <th>Edit Details</th>
              <th>View</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {addFormData.map((row, index) => (
              <tr key={index}>
                <td>{row.state}</td>
                <td>{row.district}</td>
                <td>{row.expense}</td>
                <td>{row.rawangiDate}</td>
                <td>{row.jamathNumber}</td>
                <td>{row.thajwees}</td>
                <td>{row.chosenCountry}</td>
                <td>{row.halka}</td>
                <td>{row.numberOne}</td>
                <td>{row.numberTwo}</td>
                <td><FaRegEdit size={'20px'} onClick={() => editBtn(row)} /></td>
                <td>
                  <button className='editBtn' onClick={() => editDetailBtn(row)} style={{ borderRadius: "4px", width: "95px" }}>
                    Edit Details
                  </button>
                </td>
                <td><FaEye onClick={() => viewBtn(row)} /></td>
                <td><FaTrash onClick={() => deleteform(row)} /></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default FirstTable;
