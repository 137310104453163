import React, { useState, useEffect } from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';

const SidebarComponent = ({collapsed}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Sidebar style={{ height: "100%", background: "#3333" }} collapsed={collapsed} onBackdropClick={toggleSidebar}>
      <Menu
        menuItemStyles={{
          button: {
            // the active class will be added automatically by react router
            // so we can use it to style the active menu item
            [`&.active`]: {
              backgroundColor: '#13395e',
              color: '#b6c8d9',
            },
          },
        }}
      >
        <MenuItem style={{ fontWeight: "bold", fontSize: "16px" }} component={<Link to="/admin/" />}>Table</MenuItem>
        <MenuItem style={{ fontWeight: "bold", fontSize: "16px" }} component={<Link to="/admin/add-form" />}>Add Form</MenuItem>
      </Menu>
    </Sidebar>
  )
};
export default SidebarComponent;
