import React from 'react'
import './layout.css'
import SidebarComponent from '../components/Sidebar'
import NavbarComponent from './navbar'

function Layouts({ children }) {
    const [collapsed, setCollapsed] = React.useState(true);

    return (
        <div className='main-layout'>
            <NavbarComponent collapsed={collapsed} setCollapsed={setCollapsed} />
            <div className='d-flex' style={{height:"calc(100% - 50px)"}}>
                <SidebarComponent collapsed={collapsed} setCollapsed={setCollapsed}/>
                <div style={{overflow:"auto",flex:"1"}}>
                {children}
                </div>
            </div>
        </div>
    )
}
export default Layouts
