import React from 'react';
import { Route, Routes } from 'react-router-dom';

import AddFirstForm from '../components/AddFirstForm/AddFirstForm';
import FirstTable from '../components/FirstTable/FirstTable';
import AddDetails from '../components/AddDetailForm/AddDetails';
import './DashboardRoute.css'; // Import the CSS file for the DashboardRoute component
import FormDetailEdit from '../components/FormDetailEdit/FormDetailEdit';
import Layouts from '../layouts';

function
    DashboardRoute() {
    return (

        <Layouts>
            <Routes>
                <Route path="/" element={<FirstTable />} />
                <Route path="add-form" element={<AddFirstForm />} />
                <Route path="forms/edit/:id" element={<AddFirstForm />} />
                <Route path="forms-details/:id" element={<AddDetails />} />
                {/* <Route path="userdetails/:id" element={<UserDetails />} /> */}
                <Route path="forms-detail-edit/:id" element={<FormDetailEdit />} />
            </Routes>
        </Layouts>

    );
}

export default DashboardRoute;
