import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import DashboardRoute from './Routes/DashboardRoute';
import LoginForm from './components/LoginForm/LoginForm';
import { SnackbarProvider } from 'notistack';
import './App.css';
import UserDetails from './components/UserDetails/UserDetails';

function App() {
  return (
    <Router>
       <SnackbarProvider>
      <Routes>
        <Route path="/*" element={<LoginForm />} />
        <Route path='/view/:id' element={<UserDetails/>}/>
        <Route path='/admin/*' element={<DashboardRoute />} />
      </Routes>
      </SnackbarProvider>
    </Router>
  );
}
export default App;
