import React from 'react'
import { MdPlaylistAdd } from "react-icons/md";

function NavbarComponent({setCollapsed,collapsed}) {
  return (
    <div style={{ width: "100%", height: "50px", background: "black" }}>
      <h2 className='fst-italic' style={{ padding: "10px", color: "white", fontWeight: "bold" }}>Dashboard<span style={{marginLeft:"10px"}}>
        <MdPlaylistAdd size={'30px'} onClick={() => setCollapsed(!collapsed)}/>
     </span></h2>
    </div>
  )
}
export default NavbarComponent
